<template>
  <div class=" ">
    <div class="card shadow-none mb-1 " v-if=" shipmentDetails.own_shipping ">
      <div class="card-header">
        <h6>
          <b v-text=" $t('dashboard.order.shipping.ownShipment') "> </b>
          <span class="text-warning" v-text=" ' (' + $t('dashboard.order.shipping.warning') + ')' "> </span>
        </h6>
        <p class="mb-0">
          <small v-text="$t('dashboard.order.shipping.ownShipmentMessage')"> </small>
        </p>
      </div>
      <div class="card-body">
        <div class="row ">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <p> <span v-text=" $t('general.form.carrier') + ':' "></span> </p>
              </div>
              <div class="col-6">
                <p class="text-uppercase" v-text=" shipmentDetails.carrier "> </p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <p> <span v-text=" $t('general.form.trackingNumber') + ':'  "></span> </p>
              </div>
              <div class="col-6">
                <p class="text-truncate  ">
                  <a :href="shipmentDetails.tracking_url" target="_blank"
                    v-text=" shipmentDetails.tracking_number "></a>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="card shadow-none mb-1" v-else>
      <div class="card-header">
        <div class="row align-items-center justify-content-end ">
          <div class="col-auto">
            <a class="btn btn-sm btn-info " :href="shipmentDetails.label" target="_blank"
              v-if=" shipmentDetails.label ">
              <i class=" fa fa-print mr-1 "> </i>
              <span v-text=" $t('general.button.printLabel') "></span>
            </a>
          </div>
          <div class="col-auto">
            <button class="btn btn-sm btn-outline-info" @click="fnGetOrderSummary()">
              <i class="fa fa-print mr-1"></i>
              <span v-text=" $t('general.button.orderSummary') "></span>
            </button>
          </div>
          <div class="col-auto" v-if="shipmentDetails.bill_of_landing">
            <button class="btn btn-sm btn-outline-success" @click="fnPrintBOL(shipmentDetails.bill_of_landing)">
              <i class=" fa fa-print mr-1 "> </i>
              <span v-text=" $t('tables.billOfLanding') "></span>
            </button>

            <!-- <a class="btn btn-sm btn-outline-success " :href="shipmentDetails.bill_of_landing" target="_blank"
                v-if=" shipmentDetails.label ">
                <i class=" fa fa-print mr-1 "> </i>
                <span v-text=" $t('tables.billOfLanding') "></span>
              </a> -->
          </div>

        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <p class="m-0"> <span v-text=" $t('general.form.carrier') + ':' "></span> </p>
              </div>
              <div class="col-6">
                <p class=" m-0 text-uppercase "> <span v-text=" shipmentDetails.carrier "></span> </p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <p class="m-0"> <span v-text=" $t('tables.service') + ':' "></span>
                </p>
              </div>
              <div class="col-6">
                <p class=" m-0 text-uppercase "> <span v-text=" shipmentDetails.service "></span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-12" v-if=" !orderPage">
            <div class="row">
              <div class="col-6">
                <p class=" m-0 "> <span v-text=" $t('general.form.trackingNumber') + ':' "></span>
                </p>
              </div>
              <div class="col-6">
                <p class="text-truncate m-0 ">
                  <strong>
                    <router-link :to=" `/dashboard/orders/${orderInformation.id}` "
                      v-text=" shipmentDetails.tracking_number ">
                    </router-link>
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 d-none " v-if="shipmentDetails.bill_of_landing">
            <div class="row">
              <div class="col-6">
                <p class=" m-0 "> <span v-text=" $t('tables.billOfLanding') + ': ' "></span> </p>
              </div>
              <div class="col-6">

                <p class=" m-0 ">
                  <strong>
                    <a :href="shipmentDetails.bill_of_landing" class=" w-100 " target="_blank"
                      v-text=" $t('general.button.print') ">
                    </a>
                  </strong>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="m-0">
    <div class="card shadow-none mb-1">
      <div class="card-header">
        <h6> <span v-text="$t('components.trackingNumber.packageInformation')"> </span> </h6>
      </div>
      <div class="card-body" v-if="shipmentDetails.packages.length > 0 ">
        <div class="row">
          <div class="col-6">
            <div class="">
              <div class="row">
                <div class="col-12">
                  <div class="row justify-content-between">
                    <div class="col-auto col-sm-7">
                      <p class="m-0"> <span v-text=" $t('tables.type')+ ':' "></span>
                      </p>
                    </div>
                    <div class="col-auto col sm-5">
                      <p class="m-0 text-uppercase"> <span v-text=" shipmentDetails.packages[0].type "></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row justify-content-between">
                    <div class="col-auto col-sm-7">
                      <p class="m-0"> <span v-text=" $t('tables.amount') + ':' "></span>
                      </p>
                    </div>
                    <div class="col-auto col sm-5">
                      <p class="m-0"> <span v-text=" shipmentDetails.packages[0].amount "></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 ">
                  <div class="row justify-content-between">
                    <div class="col-auto col-sm-7">
                      <p class="m-0"> <span v-text=" $t('tables.weight') + ':' "></span>
                      </p>
                    </div>
                    <div class="col-auto col sm-5">
                      <p class="m-0">
                        <span
                          v-text=" shipmentDetails.packages[0].weight + ' ' +  shipmentDetails.packages[0].weightUnit "></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="">
              <div class="row justify-content-between">
                <div class="col-auto">
                  <p class="m-0"> <span v-text=" $t('tables.length')+ ':' "></span> </p>
                </div>
                <div class="col-auto">
                  <p class="m-0">
                    <span
                      v-text=" shipmentDetails.packages[0].dimensions.length + ' ' + shipmentDetails.packages[0].lengthUnit "></span>
                  </p>
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col-auto">
                  <p class="m-0"> <span v-text="  $t('tables.width')+ ':' "></span> </p>
                </div>
                <div class="col-auto">
                  <p class="m-0">
                    <span
                      v-text=" shipmentDetails.packages[0].dimensions.width + ' ' + shipmentDetails.packages[0].lengthUnit "></span>
                  </p>
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col-auto">
                  <p class="m-0"> <span v-text="  $t('tables.height')+ ':' "></span> </p>
                </div>
                <div class="col-auto">
                  <p class="m-0">
                    <span
                      v-text=" shipmentDetails.packages[0].dimensions.height + ' ' + shipmentDetails.packages[0].lengthUnit "></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="m-0">
    <div class="card shadow-none mb-1 " v-if=" shipmentDetails.origin.first_name ">
      <div class="card-header">
        <h6> <span v-text=" $t('components.trackingNumber.warehouseAddress') "></span> </h6>
      </div>
      <div class="card-body">
        <div class=" row ">
          <div class="col-12">
            <p class="m-0"> <span v-text=" $t('tables.user') + ': ' "> </span>
              <span v-text="shipmentDetails.origin.first_name + ' ' + shipmentDetails.origin.last_name"></span>
            </p>
            <p class="m-0"> <span v-text=" $t('tables.phone') + ': ' "></span>
              <span v-text=" shipmentDetails.origin.phone "></span></p>
          </div>
          <div class="col-12">
            <p>
              <span v-text="$t('tables.address') + ': '"></span>
              <strong v-text="fnFormatAddress( shipmentDetails.origin )">
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import printJS from 'print-js';
  export default {
    name: "OrderShimpentDetails",
    props: {
      orderInformation: {
        type: Object,
        required: true,
      },
      orderPage: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        shipmentDetails: {
          packages: [],
          origin: {

          }
        },
      }
    },
    methods: {
      fnPrintBOL(link) {
        if (process.env.NODE_ENV == 'development') {
          window.open(link);
        } else {
          printJS(link);
        }
      },
      fnGetOrderSummary() {
        axios.get(`orders/summary/${this.orderInformation.id}`, {
          headers: this.headers,
          responseType: 'blob',
        }).then((response) => {
          var pdfFile = new Blob([response.data], {
            type: "application/pdf"
          });
          var pdfUrl = URL.createObjectURL(pdfFile);
          printJS(pdfUrl);
        }).catch(error => {});
      }

    },
    mounted() {
      this.shipmentDetails = this.orderInformation.shipping_items[0];
    }
  }
</script>

<style>

</style>